import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/workspace/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Honey is the community currency of 1hive, it is also an important community boundary. In order to influence the direction of 1hive, honey holders (also called `}<strong parentName="p">{`keepers`}</strong>{`) can stake their honey on proposals.`}</p>
    <p>{`In order to participate in this process you must first acquire some honey. Currently there are 4 primary ways to do this:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Get validated on brightid and register and claim honey from the `}<a parentName="p" {...{
            "href": "https://faucet.1hive.org"
          }}>{`faucet`}</a>{`. `}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Contribute to the 1hive community on github, discord, and discourse. These contributions are tracked using sourcecred to generate “pollen”, which is like a contributor score. Honey is distributed weekly on the basis of these scores.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Look for something you can do to improve 1hive and submit a funding proposal to the DAO.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Buy honey on `}<a parentName="p" {...{
            "href": "https://honeyswap.org"
          }}>{`Honeyswap`}</a>{`.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      